<template>
  <div class="cont_zar_cat_sub" @mouseover="mover = true" @mouseout="mover = false">
    <div class="blink_me" v-if="loading">.</div>
    <div class="obj_cont" v-if="items.obj.length">
      <template v-for="o in items.obj">
        <div v-if="!notEmpty || (notEmpty && o.znum)" class="obj" @click="clickedItem('obj', o)" :key="'o' + o.id" :class="{ selected: selObj && selObj.id == o.id }">
          <!-- <img class="obj_img"  :src="'/icon/' + o.icon + '.svg'" height="18" /> -->
          <ui-icon v-if="o.icon" class="obj_icon" :class="{ obj_icon_selected: selObj && selObj.id == o.id && o.icon, showIcons }" :name="o.icon" />
          <!-- <ui-icon v-if="o.icon" class="obj_icon ani_pop" :name="o.icon" size="xl" /> -->
          <a :href="baseUrl + '/?o=' + o.id" v-on:click.prevent="">
            <span class="obj_name">
              {{ o.name }}
              <i v-if="o.znum && !hideCount">{{ o.znum }}</i>
            </span>
          </a>
        </div>
      </template>
    </div>

    <div v-for="c in items.cat" :key="'c' + c.id" class="cat_cont">
      <div class="cat" @click="clickedItem('cat', c)">
        <img v-if="c.icon" :src="'/icon/' + c.icon + '.svg'" height="12" />
        <span class="cat_name">
          <!-- <span class="cat_name" :style="c.parent == 0 ? 'position:absolute; margin-left:13px;' : ''"> -->
          {{ c.name }}
          <!-- <i v-if="c.znum">{{ c.znum }}</i> -->
        </span>
      </div>
      <zar-cat-sub :hideCount="hideCount" :selObj="selObj" v-if="c.items" :items="c.items" :notEmpty="notEmpty" class="child_sub" v-on:clickedItem="clickedItem($event.tp, $event.ob)" />
    </div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import ZarCatSub from "@/comp/zar/catSub.vue";
export default {
  components: {
    ZarCatSub,
  },
  name: "zar-cat-sub",
  props: { items: Object, selObj: Object, notEmpty: Boolean, hideCount: Boolean },
  data() {
    return {
      loading: false,
      mover: false,
      showIcons: false,
      timer: null,
    };
  },
  methods: {
    clickedItem(tp, ob) {
      if (tp == "obj") {
        this.$emit("clickedItem", { tp, ob });
      }
    },
  },
  watch: {
    mover() {
      if (this.mover) {
        clearTimeout(this.timer);
        this.showIcons = true;
      } else {
        this.timer = setTimeout(() => {
          this.showIcons = false;
        }, 100);
      }
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_cat_sub {
  /* border: solid 1px #e0e0e0; */
  /* padding: 7px 0 2px 0px; */
  border-radius: 5px;
  /* background-color: #f5f5f5; */
  /* border-bottom-left-radius: 7px; */
  /* margin-bottom: 5px; */
  /* color: #000; */
  /* margin-top: 10px; */
}

.showIcons {
  opacity: 0.5 !important;
}

.child_sub {
  /* margin-left: 12px; */
  margin: 0 !important;
  padding: 0 !important;
}
.title {
  font-size: 18px;
  font-weight: 600;
  color: #666666;
}
.title span {
  text-transform: lowercase;
  margin-right: 5px;
}
.obj_name {
  /* padding: 2px 5px; */
  /* color: #1958ac; */
  color: var(--color_link);
  /* border-radius: 8px; */
  /* text-transform: uppercase; */
  font-size: 14px;
  /* font-stretch: 93%; */
  letter-spacing: -0.03em;
  padding: 6px 7px;

  line-height: 1.6;
  /* font-weight: 450; */
  /* font-weight: 420; */
  transition: background 200ms;
  margin-left: 17px;

  /* transition: font-weight 50ms; */
}
.cat_name {
  /* position: absolute; */
  /* padding: 25px 4px; */
  /* border-radius: 4px; */
  /* font-stretch: 120%; */
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  font-stretch: 100%;
  /* position: relative; */
  /* left: 2px; */
  /* color: #fff; */
  /* margin-bottom: 30px !important; */
  /* color: var(--color_head); */
  /* font-weight: 350; */
  /* background-color: #bebebe !important; */
  /* padding: 2px 3px; */
  border-radius: 3px;
  /* color: var(--color_head); */
  /* position: relative;
  top: 10px; */
}
/* .cat_name::before {
  content: ".";
  font-weight: 500;
  font-size: 20px;
  position: relative;
  top: -2px;
  color: #666;
} */

.refresh {
  display: none;
  position: relative;
  top: 2px;
  filter: invert(82%) sepia(0%) saturate(988%) hue-rotate(356deg) brightness(91%) contrast(94%);
  left: 3px;
  /* opacity: 0.6; */
}
.obj_cont {
  /* color: black; */
  /* margin-left: 2px; */
}
.cat_cont {
  /* margin-left: 10px; */
  /* border-left: solid 1px #e0e0e0; */
  /* padding-left: 7px; */
  margin-bottom: 7px;
}
.obj {
  text-align: left;
  width: fit-content;
  position: relative;
  margin-bottom: 3px;
}
.obj img {
  position: absolute;
  margin-left: 0px;
  /* filter: drop-shadow(0px 1px 1px #fff) drop-shadow(0px 2px 2px #fff) drop-shadow(0px 3px 3px #fff) drop-shadow(0px -1px 3px #fff) drop-shadow(0px -2px 4px #fff) drop-shadow(0px -3px 5px #fff); */
  /* box-shadow: 0px 0px 2px #fff; */
  /* filter: drop-shadow(-3px 0px 5px #fff) drop-shadow(-3px 0px 5px #fff) drop-shadow(-3px 0px 5px #fff) drop-shadow(-3px 0px 5px #fff); */
}
.selected .obj_name {
  /* font-weight: 700; */
  /* letter-spacing: 0.01em; */
  /* background-color: var(--color_obj_light); */
  /* padding: 5px 10px; */
  background-color: var(--color_selected);
  /* color: #fff; */
  /* font-stretch: 120%; */
  font-size: 14px;
  /* transform: scale(2); */
  transition: all 50ms;
  /* color: #fff; */
  /* background-color: var(--color_selected); */
  /* background: radial-gradient(circle, #d5ebff 0%, #ffffff 100%); */

  /* font-family: "Raleway"; */
  /* letter-spacing: -0.03em; */
  /* font-family: "Fira Sans", sans-serif; */
  /* background-color: #e4ffc6; */
  border-radius: 3px;
  /* color: var(--color_highlight); */
  /* font-size: 15px; */
  /* transform: scale(2) !important; */
  /* color: var(--color_link); */
  /* font-weight: 700; */
}

.selected .obj_name i {
  color: #555555;
  font-weight: var(--base_weight);
}

.obj_name i,
.cat_name i {
  color: #a0a0a0;
  font-size: 11px;
  font-weight: var(--base_weight) !important;
  font-weight: inherit;
  position: relative;
  /* top: -1px; */
  /* font-family: "Roboto Mono"; */
}
.selected .obj_img {
  filter: invert(18%) sepia(62%) saturate(3965%) hue-rotate(356deg) brightness(93%) contrast(80%);
}

.obj_icon {
  transition: all 200ms;
  position: relative;
  left: 0px;
  top: 0px;
  opacity: 0.3;
  height: 18px;
}

.obj_icon_selected {
  opacity: 1 !important;
  transform: scale(1.1);
}

.cat {
  width: fit-content;
}

.cat img {
  margin-top: 5px;
  position: relative;
}
i {
  font-size: 11px;
  font-style: normal;
}
/*
i::before {
  content: "(";
  margin: 0 1px;
  font-size: 8px;
  position: relative;
  top: -2px;
  color: #a0a0a0;
}
i::after {
  content: ")";
  margin: 0 1px;
  font-size: 8px;
  position: relative;
  top: -2px;
  color: #a0a0a0;
}
*/
.blink_me {
  position: absolute;
  margin-left: 115px;
  margin-top: -18px;
}

@media only screen and (min-width: 701px) {
  .cat:hover {
    cursor: pointer;
  }
  .obj_name:hover {
    cursor: pointer;
    background-color: #f1f1f1;
    border-radius: 3px;
  }
  .selected .obj_name:hover {
    background-color: var(--color_selected);
  }
  .obj:hover > .obj_icon {
    opacity: 1 !important;
  }
}

@media only screen and (max-width: 700px) {
  .obj_name {
    padding: 7px 10px !important;
    font-size: 16px;
    line-height: 2;
    margin-left: 30px;
    background-color: #f1f1f1;
    border-radius: 5px;
    font-weight: var(--base_weight);
    width: 100px;
    white-space: nowrap !important;
  }

  .obj_name:active {
    background-color: var(--color_selected);
  }
  .obj_icon {
    height: 24px;
    opacity: 1;
    top: 2px;
  }
  .obj_cont {
    padding-left: 5px;
  }
  .cat_name {
    font-size: 14px;
    text-transform: uppercase !important;
  }
  .cat {
    margin-bottom: 7px;
  }
}
</style>
