<template>
  <div class="cont_ui_tag" v-if="items.length">
    <ui-icon name="i-tag2" size="xxl" class="po-re mr-2 top-5" />
    <span v-for="(t, i) in items" :key="'tag' + i" @click="clickedItem(t)" class="ani_pop" :class="['tag', t.class || 'simple', t.icon && 'with_icon', !controllable && 'not_controllable']">
      <ui-icon class="tag_icon" v-if="t.icon" :name="t.icon" size="xl" />{{ t.sname }} <ui-icon v-if="t.class != 'special' && controllable" name="i-close3" class="remove_sym" />
    </span>
    <span @click="clearTags()" class="link delete no-wrap" v-if="showClearTags && controllable"><ui-icon name="i-delete" size="xl" /><span>АРИЛГАХ</span></span>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
// import smoothReflow from "vue-smooth-reflow";
export default {
  name: "ui-tag",
  // mixins: [smoothReflow],
  props: {
    items: Array,
    showClearTags: Boolean,
    controllable: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    clickedItem(o) {
      if (!this.controllable) return;
      this.$emit("clickedItem", o);
    },
    clearTags() {
      if (!this.controllable) return;
      this.$emit("clearTags");
    },
  },
  mounted() {
    // this.isMobile && this.$smoothReflow();
  },
  computed: {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_tag {
  max-width: 95%;
  transition: 0.15s ease-out;
  position: relative;
  /* padding: 0 5px; */
  word-wrap: break-word;
}

.tag {
  display: inline-block;
  cursor: pointer;
  margin: 0px 4px 5px 0;
  /* border: solid 2px #ccc; */
  background-color: var(--color_blue);
  transition: all 0.15s;
  border-radius: 15px;
  color: #fff;
  /* font-family: "Regular"; */
  /* text-transform: uppercase; */
  user-select: none;
  position: relative;
  font-weight: 500;
  gap: 5px;
  /* line-height: 1; */
  /* letter-spacing: 0.03em; */
  /* line-height: 1.4em; */
  /* text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3); */
  white-space: nowrap;
  font-size: 13px;
  padding: 6px 8px 4px 5px;
  line-height: 1;
  /* line-height: 1; */
}

.tag.not_controllable {
  /* padding-right: 7px; */
}

.tag:active {
  transform: translateY(1px);
}

.tag:hover .remove_sym {
  opacity: 1;
  filter: brightness(0.9);
  /* filter: invert(100%) sepia(3%) saturate(20%) hue-rotate(89deg) brightness(103%) contrast(100%); */
}

.with_icon {
  /* padding-left: 33px !important; */
}

.tag_icon {
  position: relative;
  vertical-align: middle;
  margin: 0 5px;
  top: 0px;
  filter: invert(100%) sepia(3%) saturate(20%) hue-rotate(89deg) brightness(103%) contrast(100%);
}

.remove_sym {
  color: #fdfdfd;
  position: relative;
  top: 2px;
  opacity: 0.5;
  transition: opacity 300ms;
  filter: invert(100%) sepia(3%) saturate(20%) hue-rotate(89deg) brightness(103%) contrast(100%);
}
.sort {
  /* border: solid 1px #dddddd; */
  color: #fff;
}
.special {
  /* background-color: rgb(236, 255, 212); */
  /* font-size: var(--14px); */
  /* font-weight: 500; */
  /* box-shadow: 0px 1px 0px 1px rgb(183, 212, 150); */
  background-color: #adfa1d;
}
/* .special::after {
  content: "/";
  font-weight: 300;
  font-size: 8px;
  position: relative;
  padding: 0 0 0 7px;
  top: -1px;
  color: #000;
  left: -4px;
} */
.main {
  /* background-color: rgb(236, 255, 212); */
  /* border: none; */
  /* font-size: var(--14px); */
  /* padding: 0; */
  /* font-weight: 500; */
  /* color: var(--color_darker); */
  /* box-shadow: 0px 1px 0px 1px rgb(183, 212, 150); */
  /* background-color: #e8f4ff; */
  /* margin-right: 0 !important; */
  /* color: #999; */
  /* background-color: #adfa1d; */
  /* background-color: #dbdbdb; */
}
.page {
  background-color: var(--color_selected);
}
.main:hover,
.simple:hover,
.sort:hover {
  /* border: solid 1px #8b8b8b; */
  /* background-color: #f1f1f1; */
  filter: brightness(0.9);
}
.disabled {
  background-color: #f7f7f7;
  font-weight: 600;
  color: #8a8a8a;
  border: solid 1px #cfcfcf;
  border-top: solid 1px #e4e4e4;
}
.disabled:hover {
  cursor: default;
}
.disabled i,
.simple i,
.special i,
.sort i {
  font-style: initial;
  font-weight: 200;
  opacity: 1;
  color: #000;
}
.special:active,
.simple:active {
  /* transform: translateY(1px); */
}

.delete {
  font-size: 12px;
  position: relative;
  top: 7px;
  left: 1px;
  display: inline;
  /* line-height: 0.5em; */
  align-items: center;
  text-align: center;
  opacity: 0.7;
  transition: opacity 150ms;
  /* padding-left: 20px; */
  /* filter: drop-shadow(0px 0px 1px #44ddd5) drop-shadow(0px 0px 1px #44ddd5); */
}

.delete img {
  position: relative;
  left: 1px;
  top: -2px;
}

.delete:hover {
  /* background-color: #dbdbdb; */
  /* background: none; */
  opacity: 1;
}

.delete span {
  position: relative;
  top: -6px;
  left: 6px;
  color: var(--color_link);
  font-weight: 500;
  font-stretch: 95%;
}

/* .clear_all {
  position: relative;
  top: -5px;
  left: 0px;
  color: #000;
font-weight: var(--base_weight);
  font-size: smaller;
} */

.seperator {
  color: #ccc;
}
.blink_me {
  position: absolute;
  margin-left: 115px;
  margin-top: -18px;
}
/* @media only screen and (max-width: 700px) {
  .cont_ui_tag {
    text-align: left;
  }
  .tag {
    font-size: 16px;
    font-weight: 450;
    padding: 4px 6px 4px 3px;
  }
  .remove_sym {
    margin-left: -2px;
    opacity: 0.3;
  }
} */
</style>
