<template>
  <div class="cont">
    <ui-navi-bottom :fullWidth="fullWidth" :items="naviItems" v-on:clicked="clicked($event)" />
    <audio id="noti">
      <!-- <source src="sound/tap.wav" type="audio/wav" /> -->
      <source src="sound/noti.mp3" type="audio/mpeg" />
    </audio>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import uiNaviBottom from "@/comp/ui/naviBottom.vue";
export default {
  components: { uiNaviBottom },
  name: "main-navi",
  props: { fullWidth: Boolean },
  data() {
    return {};
  },
  methods: {
    clicked(e) {
      if (e.name == "home") {
        this.showHome();
        // if (this.$route.name == "home") this.$router.go("");
        // else this.$router.push({ name: "home", obj: 1 });
      }
      if (e.name == "add") {
        this.$router.push({ name: "zarAdd" });
      }
      if (e.name == "zarView") {
        this.$router.push({ name: "zarView", query: { o: this.witem.obj, z: this.witem.zid } });
      }
      if (e.name == "favs") {
        // this.$router.push({ name: "userHome", query: { tab: "favs" } });
        if (this.logged) this.$bus.emit("openDrawer", { name: "userFavs", dataSet: this.favsCount });
        else this.$bus.emit("openModal", { name: "userLogin" });
      }
      if (e.name == "noti") {
        this.$bus.emit("openModal", { name: this.logged ? "userNoti" : "userLogin" });
      }
      if (e.name == "user") {
        if (this.logged) this.$router.push("/user/home");
        else this.$bus.emit("openModal", { name: "userLogin" });
      }
      if (e.name == "category") {
        this.showMenu.cat = true;
      }
    },
    showHome() {
      if (this.$route.name != "home") {
        this.$router.push({ name: "home" });
        if (this.homeUrl) history.replaceState(null, null, this.homeUrl);
        return;
      }

      if (this.$route.name == "home" && this.selCat && this.selCat.id != 11) {
        this.selCat = { id: 11, name: "Нүүр" };
        this.$router.push({ name: "home" });
        return;
      }

      if (this.$route.name == "home" && this.selCat && this.selCat.id == 11) {
        this.$router.push({ name: "home", obj: 1 });
        this.$router.go("");
        return;
      }
    },
  },

  computed: {
    showMenu: {
      get() {
        return this.$store.state.showMenu;
      },
      set(ob) {
        this.$store.commit("showMenu", ob);
      },
    },
    witem() {
      return this.$store.state.zar.witem;
    },
    openDrawers() {
      return this.$store.state.openDrawers;
    },
    selCat: {
      get() {
        return this.$store.state.selCat;
      },
      set(ob) {
        this.$store.commit("selCat", ob);
      },
    },
    homeUrl() {
      return this.$store.state.homeUrl;
    },
    // modals() {
    //   return this.$store.state.modals;
    // },
    route() {
      return this.$route.name;
    },
    routeTab() {
      let urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("tab");
    },
    logged() {
      return this.$store.state.auth.status.logged;
    },
    newNoti() {
      let items = this.$store.state.user.noti;
      if (!items || !items.length || items.constructor !== Array) return false;

      let found = items.find((e) => !e.seen);
      return found;
    },
    favsCount() {
      return this.$store.state.user.favs.length;
    },
    favsModalActive() {
      return this.$store.state.user.favsModalActive;
    },
    notiModalActive() {
      return this.$store.state.user.notiModalActive;
    },
    scr() {
      return this.$store.getters.scr;
    },
    naviItems() {
      let items = [];

      if (this.scr < 3) items.push({ name: "category", icon: "i-menu2", icon_act: "i-menu2", act: this.openDrawers.includes("showMenuCat"), dis: false, orangeDot: false });

      items.push({ name: "home", icon: "i-home", icon_act: "i-home2", act: this.$route.name == "home", dis: false, orangeDot: false }, { name: "user", icon: this.userIcon, icon_act: "i-user2", act: this.$route.name == "userHome", dis: false, orangeDot: false });

      if (this.logged) {
        items.push({ name: "favs", icon: "i-bookmark", icon_act: "i-bookmark2", act: this.favsModalActive, count: this.favsCount, dis: false, orangeDot: false }, { name: "noti", icon: "i-noti", icon_act: "i-noti2", act: this.notiModalActive, dis: false, orangeDot: this.newNoti });
      }

      items.push({ name: "add", icon: "i-add", icon_act: "i-add2", act: this.$route.name == "zarAdd", dis: false, orangeDot: false });
      return items;
    },
    userIcon() {
      return this.logged ? "i-user" : "i-login";
    },
  },

  watch: {
    // newNoti() {
    //   if (this.newNoti) {
    //     const audio = document.getElementById("noti");
    //     audio.muted = true; // without this line it's not working although I have "muted" in HTML
    //     audio.play();
    //   }
    // },
    // modals() {
    //   this.naviItems.find((item) => item.name == "favs" && (item.act = this.modals.find((e) => e.name == "favs")));
    // },
    // route: {
    //   immediate: true,
    //   handler() {
    //     this.naviItems.forEach((item) => {
    //       if (item.name == "home") item.act = this.$route.name == "home";
    //       if (item.name == "add") item.act = this.$route.name == "zarAdd";
    //       if (item.name == "user") item.act = this.$route.name == "userHome";
    //     });
    //   },
    // },
    // logged: {
    //   immediate: true,
    //   handler() {
    //     let userIcon = this.naviItems.find((e) => e.name == "user");
    //     if (this.logged) userIcon.icon = "i-user-logged";
    //     else userIcon.icon = "i-user";
    //   },
    // },
  },
  mounted() {
    this.$bus.on("showHome", this.showHome);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
