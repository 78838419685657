<template>
  <div class="cont_zar_content" v-if="items">
    <div class="cont_center">
      <span v-for="(i, k) in items" :key="'cnt' + k" class="cont_item">
        <div v-if="i.ptype == 'title' && !spo" class="time_since">
          {{ data.date }} <span class="sep2">|</span> {{ data.rdate }} <br class="sep2" v-if="nearLoc" />
          <span v-if="nearLoc"> {{ nearLoc == 1 ? "Нэг газар (өдрийн цагаар)" : "Нэг газар (оройн цагаар)" }}</span>
          <span :class="'pulse_container' + nearLoc" v-if="nearLoc">
            <div class="po-ab">
              <div class="dot"></div>
              <div class="pulse"></div>
            </div>
          </span>
          <span :set="(tl = checkExpireTime(data.obj, data.rdateRaw))"></span>
          <span v-if="tl && tl.status == 'aboutExpire'" class="aboutExpire">{{ tl.leftTime == 0 ? "Зарын хугацаа дуусахад хэдэн цаг үлдсэн" : "Зарын хугацаа дуусахад " + tl.leftTime + " өдөр үлдсэн" }}</span>
        </div>
        <div v-if="i.val" :class="{ field: true, [i.ptype]: true, [i.ptype + '_dep_' + data.dep]: true, [i.ptype + '_obj_' + data.obj]: true }">
          <span v-if="i.pcomp_tp == 3">
            <slot />
            <keep-alive :key="data.zid">
              <ui-photos :items="i.photoData" byOne :drawerMode="drawerMode" />
            </keep-alive>
          </span>

          <!-- <span v-else-if="i.pcomp_tp == 5">
            <span class="prop_name" :class="{ center_prop_name: centerPropName }">{{ i.name }}:</span>
            <span v-for="(v, vi) in i.val" :key="vi">
              {{ v.name }} <span v-if="i.unit" class="unit">{{ v.unit }}</span>
            </span>
          </span> -->

          <span v-else-if="i.pcomp_tp == 5">
            <span class="prop_name" :class="{ center_prop_name: centerPropName }">{{ i.name }} </span>
            <span class="prop_val">
              <span v-for="(v, vi) in i.val" :key="'prp' + vi" class="tag">
                {{ v.name }} <span v-if="i.unit" class="unit">{{ i.unit }}</span>
              </span>
            </span>
          </span>

          <span v-else-if="i.pcomp_tp == 6">
            <ui-video-player :src="apiUrl + '/media/zar/' + data.obj + '/' + data.zid + '/' + i.id + '/' + i.val" :sw="sw" />
            <!-- <video :width="sw" controls autoplay>
            <source :src="apiUrl + '/media/zar/' + data.obj + '/' + data.zid + '/' + i.id + '/' + i.val" type="video/mp4" />
            Your browser does not support HTML video.
          </video> -->
          </span>

          <span v-else-if="i.pcomp_tp == 7">
            <ui-reflow :disabled="!isMobile">
              <div>
                <ui-button v-if="!showLocPoint" v-on:clicked="showLocPoint = true" icon="i-map2" class="pr-3 pl-3 mb-3" text="Байршил харах" tp="simple" />
                <ui-location v-if="i.val && showLocPoint" :coord="[i.val.lat, i.val.lng]" />
              </div>
            </ui-reflow>
          </span>

          <!-- <span v-else-if="i.pcomp_tp == 7">
            <div>
              <l-map style="height: 350px" :zoom="12" :center="[i.val.lat, i.val.lng]" class="raw_map">
                <l-tile-layer :url="showSatView ? urlSat : url"></l-tile-layer>
                <l-marker :lat-lng="[i.val.lat, i.val.lng]" :icon="licon"></l-marker>
                <l-control-fullscreen position="topleft" :options="{ title: { false: 'Томруулах', true: 'Жижигсгэх' } }" />
              </l-map>
              <a :href="'https://www.google.com/maps/@' + i.val.lat + ',' + i.val.lng + ',' + '16z'" target="blank">view on google map</a>
            </div>
            <br />
          </span> -->

          <span v-else-if="i.pcomp_tp == 8">
            <ui-youtube-view v-if="i.val" :items="JSON.parse(i.val)" />
          </span>

          <span v-else-if="i.ptype == 'location_point'">
            <ui-reflow :disabled="!isMobile">
              <div>
                <ui-button v-if="!showLocPoint" v-on:clicked="showLocPoint = true" icon="i-map2" class="pr-3 pl-3 mb-3" text="Байршил харах" tp="simple" />
                <ui-location v-if="i.val && showLocPoint" :coord="JSON.parse(i.val)" />
              </div>
            </ui-reflow>
          </span>

          <span v-else-if="i.ptype == 'location_area'">
            <div v-if="!showLocArea">
              <span class="prop_name pb-1" :class="{ center_prop_name: centerPropName }">Байршил </span><span class="prop_val">{{ i.valData.name }}</span>
            </div>
            <ui-button :class="{ 'ml-10px': scr == 1 }" v-if="!showLocArea" v-on:clicked="showLocArea = true" icon="i-map2" class="pr-3 pl-3" text="Байршил харах" tp="simple" />
            <ui-location-area v-if="i.val && showLocArea" :area="{ id: i.val, name: i.valData.name }" />
            <div v-if="!showLocArea" class="mb-2"></div>
          </span>

          <span v-else>
            <span class="prop_name" :class="{ center_prop_name: centerPropName }">{{ i.name }} </span>
            <span class="prop_val">
              <template v-if="i.ptype == 'make_time' && data.obj == 1"><span v-html="i.val"></span> {{ i.unit }} <span class="make_time_progress" :style="{ background: 'linear-gradient(to right, #00b7ff ' + calcTimePercent(i.val, 25) + '%, #fff 0%)' }"></span></template>
              <template v-else-if="i.ptype == 'mgl_time' && data.obj == 1"><span v-html="i.val"></span> <span class="mgl_time_progress" :style="{ background: 'linear-gradient(to right, #15b700 ' + calcTimePercent(i.val, 25) + '%, #fff 0%)' }"></span></template>
              <template v-else-if="i.ptype == 'odometer' && data.obj == 1"><span v-html="i.val"></span> {{ i.unit }} <span class="odometer_progress" :style="{ background: 'linear-gradient(to right, #eecc6e ' + calcTimePercent(i.val, 300) + '%, #fff 0%)' }"></span></template>
              <template v-else>
                <ui-icon v-if="i.ptype == 'price'" class="coin" name="i-coin" size="xl" />
                <span v-if="i.ptype == 'price' && data.oldPrice" class="mr-1 old_price">
                  <!-- <span class="tugrug">₮</span> -->
                  <span v-if="i.conver" v-html="converters(i.conver, data.oldPrice)" class="value" />
                  <span v-else class="value">{{ data.oldPrice }} {{ i.unit }}</span>
                </span>
                <ui-icon v-if="i.icon && i.icon != 'empty'" class="icon" :name="i.icon" />
                <ui-icon v-if="i.valData && i.valData.icon && i.valData.icon != 'empty'" size="xl" class="icon mr-1" :name="i.valData.icon" />
                <span v-if="i.conver" v-html="i.val" />
                <span v-else class="val">{{ i.valData && i.valData.name ? i.valData.name : i.val }} </span>
                <span v-if="i.unit && i.conver != 'shift_million' && i.conver != 'shift_billion'" class="unit"> {{ i.unit }}</span>
              </template>
            </span>
            <div v-if="i.ptype == 'barter' && bofferEnabled" class="mt-1 mb-1">
              <div v-if="bofferNum" class="mb-3">
                <span v-if="owner" class="boffer_num clickable" @click="showModalBoffer()"> Ирсэн машины солих санал: {{ bofferNum }}</span>
                <span v-else class="boffer_num"> Ирсэн машины солих санал: {{ bofferNum }}</span>
              </div>
              <ui-button v-on:clicked="showAddBoffer()" class="pr-3 pl-3" text="Машин санал болгох" tp="special" :class="{ 'ml-10px': scr == 1 }" />
            </div>
          </span>
        </div>

        <!-- <div v-if="i.val && i.ptype == 'spec'" :class="{ field: true, [i.ptype]: true, [i.ptype + data.dep]: true, [i.ptype + '_obj_' + data.obj + '_prop_' + i.id]: true }">

          <span v-else>
            <span class="prop_name" :class="{ center_prop_name: centerPropName }">{{ i.name }}: </span>
            <span class="prop_val">
              <ui-icon v-if="i.valData && i.valData.icon && i.valData.icon != 'empty'" class="icon mr-1" :name="i.valData.icon" />{{ i.valData && i.valData.name ? i.valData.name : i.val }}<span v-if="i.unit" class="unit"> {{ i.unit }}</span>
            </span>
          </span>
        </div> -->
        <ui-reflow>
          <div v-if="i.ptype == 'body' && !seenOwnBanner" class="banner_content" v-observe-visibility="reachedOwnBanner">
            <img :src="apiUrl + '/media/banners/banner2.png'" />
          </div>
        </ui-reflow>
      </span>
    </div>
  </div>
  <!--img class="prev" v-if="prevItem && prevItem.image" @click="goPrev()" :src="apiUrl + '/media/zar/' + prevItem.obj + '/' + prevItem.zid + '/' + prevItem.image.id + '/' + 't' + prevItem.image.items[0].id + '.jpg'" />
    <img class="next" v-if="nextItem && nextItem.image" @click="goNext()" :src="apiUrl + '/media/zar/' + nextItem.obj + '/' + nextItem.zid + '/' + nextItem.image.id + '/' + 't' + nextItem.image.items[0].id + '.jpg'" /-->
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
// import L from "leaflet";
// import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import uiPhotos from "@/comp/ui/photos.vue";
import uiLocation from "@/comp/ui/location.vue";
import uiLocationArea from "@/comp/ui/locationArea.vue";
import uiYoutubeView from "@/comp/ui/youtubeView.vue";
//import { Icon } from "leaflet";
// import LControlFullscreen from "vue2-leaflet-fullscreen";
import uiVideoPlayer from "@/comp/ui/videoPlayer.vue";

/*
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
*/
export default {
  name: "zar-content",
  props: { items: Array, data: Object, centerPropName: Boolean, nearLoc: [Boolean, Number], spo: Number, bofferEnabled: Boolean, bofferNum: Number, owner: Boolean, drawerMode: Boolean },
  components: {
    uiPhotos,
    // LMap,
    // LTileLayer,
    // LMarker,
    // LControlFullscreen,
    uiVideoPlayer,
    uiYoutubeView,
    uiLocation,
    uiLocationArea,
  },
  data() {
    return {
      // url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      // urlSat: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png",
      // licon: L.icon({
      //   iconUrl: "/icon/pin.svg",
      //   iconSize: [32, 37],
      //   iconAnchor: [16, 37],
      // }),
      user: null,
      showSatView: false,
      showLocArea: false,
      showLocPoint: false,
    };
  },
  methods: {
    checkExpireTime(obj, itemDate) {
      if (!this.catData || !itemDate) return false;

      let object = this.catData.obj.find((e) => e.id == obj);
      if (!object || !object.configs) return false;

      let expireTime = JSON.parse(object.configs)["expire_time"];
      if (!expireTime) return false;

      let now = Math.floor(Date.now() / 1000);
      let day = 60 * 60 * 24;

      let status = false;
      if (itemDate < now - day * expireTime) status = { status: "expired" };
      else {
        let leftTime = itemDate - (now - day * expireTime);
        if (leftTime < day * 3) status = { status: "aboutExpire", leftTime: Math.floor(leftTime / day) };
      }
      return status;
    },
    calcTimePercent(val, total) {
      return (parseFloat(val) * 100) / total;
    },
    reachedOwnBanner() {
      setTimeout(() => {
        this.seenOwnBanner = true;
      }, 20000);
    },
    reset() {
      this.showSatView = false;
      this.showLocArea = false;
      this.showLocPoint = false;
    },
    showModalBoffer() {
      this.$bus.emit("openModal", { name: "bofferList", dataSet: { url: "/module/barter/getBofferUserZid/" + this.data.obj + "/" + this.data.zid } });
    },
    showAddBoffer() {
      if (this.owner) {
        this.showMessage("info", "Таны зар байна");
        return;
      }
      this.$emit("showAddBoffer");
    },
  },
  computed: {
    catData() {
      return this.$store.state.zar.catData;
    },
    seenOwnBanner: {
      get() {
        return this.$store.state.seenOwnBanner;
      },
      set(bool) {
        this.$store.commit("seenOwnBanner", bool);
      },
    },
    sw() {
      let w = this.$store.state.swidth;
      return w > 694 ? 694 : w - 30;
    },
    scr() {
      return this.$store.getters.scr;
    },
  },
  watch: {
    items() {
      this.reset();
    },
  },
  mounted() {
    // console.log(this.data);
  },
  created() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_zar_content {
  position: relative;
  max-width: 600px;
  /* font-size: 13px; */
}

/* @media only screen and (max-width: 700px) {
  .cont_zar_content {
    padding-left: 0px !important;
  }
} */

.cont_item {
  position: relative;
}
.cont_item:has(.year) {
  display: inline-block;
  margin-left: 50%;
  transform: translateX(-50%);
}

.cont_item:has(.imp_year) {
  display: inline-block;
  position: relative;
  left: -20px;
}
.banner_content {
  width: 100%;
  text-align: center;
  margin: 20px 0 10px 0;
}
.banner_content img {
  height: 450px;
}

.prop_name {
  width: auto;
  display: inline-block;
  margin: 0 5px 5px 5px;
  font-size: 14px;
  color: #666666;
  font-stretch: 100%;
  font-weight: 400;
  /* font-weight: 350; */
  /* line-height: 1.8; */
}

/* .prop_name::after {
  content: "|";
  font-weight: 200;
  font-size: 10px;
  color: #666;
  position: relative;
  top: 0px;
  left: 1px;
  padding: 0 5px;
} */

.center_prop_name {
  min-width: 180px;
  text-align: left;
}

.prop_val {
  font-weight: 600;
  font-size: 14px;
  /* font-family: bitter, serif; */
  /* font-family: "Charis SIL", serif; */
  /* font-family: "Piazzolla", serif;  */
  /* font-family: finland, serif; */
  /* font-family: smaller; */
  color: #000;
  /* font-stretch: 140%; */

  /* letter-spacing: -0.01em; */
  /* font-stretch: expanded; */
}

.field {
  line-height: 147%;
}
.coin {
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.time_since {
  font-size: 14px;
  font-weight: var(--base_weight);
  letter-spacing: 0em;
  line-height: 180%;
  margin-bottom: 20px;
  margin-top: 5px;
  /* margin-left: 10px; */
  background-color: #f1f1f1;
  width: fit-content;
  padding: 6px 30px 4px 30px;
  line-height: 1.5;
}

.sep {
  position: relative;
  margin: 0 3px;
  font-size: 15px;
  color: #bdbdbd;
  top: -2px;
  font-weight: bold;
}

.sep2 {
  position: relative;
  font-size: 8px;
  top: -2px;
  margin: 0 5px;
  color: #bdbdbd;
}

/* COMMON -----------------------------------------------------------------------------------------------------*/

.date {
  font-size: 12px;
  color: #8b8b8b;
}
.date:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}
.title {
  /* font-size: calc(3 * (1vw)); */
  text-align: center;
  position: relative;
  /* margin-top: 10px; */
  /* padding: 0.1em 0.5em; */
  margin-bottom: 2px;
  line-height: 1.4;
  width: fit-content;
}

.title .prop_val {
  font-family: bitter, system-ui;
  /* letter-spacing: 0.01em !important;
  word-spacing: 0.2em; */
  font-stretch: 100%;
  letter-spacing: 0.01em;
  font-weight: 550;
  font-size: 18px;
  word-wrap: anywhere;
  /* font-size: calc(0.3em + 1vw); */
}

.title:first-letter {
  text-transform: capitalize;
}

.unit {
  font-weight: 370;
  font-size: 0.9em;
}

.price {
  font-weight: inherit;
  letter-spacing: -0.02em;
  margin: 12px 0 15px 0;
  background-color: #a7e731;
  width: fit-content;
  padding: 0.8em 1em 0.5em 1em;
  white-space: nowrap;
  /* border-radius: 3px; */
  /* text-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15); */
}

/* .price img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(226deg) brightness(104%) contrast(102%);
} */

.price .unit {
  font-size: 16px;
  font-weight: var(--base_weight);
  margin-left: 0px;
}
.price .prop_name {
  font-size: 14px;
  width: fit-content;
  margin-right: 5px;
  display: none;
}
.price .prop_val {
  font-size: 24px;
  font-weight: 450;

  /* font-stretch: 130%; */
  letter-spacing: -0.01em;
}

.price.val::after {
  content: "₮";
  font-weight: 300;
  margin-right: 1px;
}

.price .sep {
  display: none;
}

.odometer_progress,
.make_time_progress,
.mgl_time_progress {
  width: 100px;
  height: 10px;
  line-height: 10px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  top: 2px;
}

.odometer_progress {
  border: solid 1px #eecc6e;
}
.make_time_progress {
  border: solid 1px #00b7ff;
}

.mgl_time_progress {
  border: solid 1px #15b700;
}
/* 
.model,
.town,
.make_obj_2,
.make_obj_3 {
  display: block;
  width: fit-content;
  text-transform: uppercase;
  margin-bottom: 10px;
  white-space: nowrap;
} */
.make_obj_3,
.make_obj_2 {
  width: 100% !important;
  text-align: center !important;
}

.model {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

.model .prop_val,
.town .prop_val,
.make_obj_2 .prop_val,
.make_obj_3 .prop_val {
  font-size: 26px !important;
  font-weight: 550;
  font-stretch: 90%;
}
.image {
  margin-top: 10px !important;
}
.town {
  display: block;
}

.imp_year {
  display: inline-block;
  font-size: 19px;
  font-stretch: 90%;
}
.imp_year .prop_name {
  display: none;
}

.year .unit,
.imp_year .unit {
  display: none;
}

.imp_year .val::before {
  position: relative;
  content: "/";
  font-size: 11px;
  font-weight: 300;
  top: -2px;
  left: 0px;
  margin: 0 6px 0 5px;
  color: #6b6b6b;
}

.location {
  margin: 20px 0 10px 0;
}

.specfname {
  color: #2b2b2b;
}
.year {
  display: inline-block;
}
.year .val {
  font-weight: inherit;
}
.condition .val,
.make_time .val {
  font-size: 1em;
}
.make_time {
  margin-bottom: 0px;
}
.body {
  margin: 15px 0 0px 0;
  line-height: 150%;
  white-space: pre-line;
  padding: 0 10px 0 7px;
  width: 95%;
  /* font-stretch: 110%; */

  /* border-top: dashed 1px #ccc; */
}

/* .body::before {
  content: open-quote;
  font-weight: bold;
  font-size: 18px;
  color: #bbbbbb;
  margin-right: 0.2em;
} */

/* .body::after {
  margin-left: 0.2em;

  content: close-quote;
  font-weight: bold;
  font-size: 16px;
  color: #bbbbbb;
} */

.body .prop_val {
  font-weight: 440;
  letter-spacing: 0.01em !important;
  word-spacing: 0.2em;
  word-wrap: anywhere;
  /* font-stretch: 120%; */
  /* font-family: bitter; */
  color: #000;
  font-size: 17px;
  line-height: 1.5em;
  font-family: bitter, system-ui;
}

.body .prop_name,
.title .prop_name,
.make .prop_name,
.model .prop_name,
.year .prop_name,
.imp_year .prop_name,
.town .prop_name,
.types_dep_2.types_obj_9 .prop_name {
  display: none;
}

/* DEP 1 -----------------------------------------------------------------------------------------------------*/
.town {
  text-align: center;
  width: 100%;
}

/* DEP 2 -----------------------------------------------------------------------------------------------------*/
.make_dep_2 {
  /* display: inline; */
  font-size: 18px;
  text-transform: uppercase;
}
.make_obj_1 {
  display: block;
  width: 100%;
  text-align: center;
}
.make_obj_1 .val {
  font-weight: var(--base_weight);
  font-stretch: 140%;
  letter-spacing: 0.1em;
}
.wheel_tire {
  display: table-cell;
  padding-left: 10px;
  font-size: 20px;
}
.make_time_dep_2 .prop_val {
  color: #00489b;
  font-weight: 600;
}
.mgl_time_dep_2 .prop_val {
  color: green;
  font-weight: 600;
}
.year_dep_2 {
  display: inline-block;
  /* margin-bottom: 20px; */
}
.year_dep_2 .prop_val {
  font-size: 22px;
  font-weight: 600;
  font-stretch: 90%;
}
.tire_size_width_obj_17 {
  padding-left: 8px;
}
.made_for_obj_17 {
  display: inline;
}
.made_for_obj_17 .val {
  font-size: 20px;
  font-weight: var(--base_weight) !important;
}
.moto_type_obj_20 .val,
.heavy_type_obj_20 .val,
.bus_body_obj_20 .val {
  margin-left: 10px;
  font-weight: var(--base_weight) !important;
}

.types_dep_2.types_obj_9 {
  display: inline-table;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

.types_dep_2.types_obj_9 .val {
  font-weight: 500;
  font-size: 26px;
}
/* .year_dep_2.year_obj_9 {
  display: inline-table;
} */

.make_obj_10 .val {
  font-size: 26px;
  font-weight: 550;
  font-stretch: 90%;
}
.make_obj_10::after {
  content: "Мото";
  font-size: 26px;
  font-weight: 300;
  font-stretch: 90%;
}

.mgl_time .unit {
  display: none;
}

.make_obj_11 .prop_name,
.model_obj_11 .prop_name {
  display: inherit;
}
.make_obj_11,
.model_obj_11 {
  font-size: inherit !important;
  display: block;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.make_obj_13 {
  font-size: 26px;
}
.tire_size_obj_13 {
  padding-left: 10px;
}
.tire_size_obj_13::after {
  content: "инч обуд";
  font-size: 18px;
}
.tire_size_obj_17::after {
  content: "инч дугуй";
  font-size: 18px;
}
.tire_size_height_obj_17::before,
.tire_size_obj_17::before {
  content: "/";
  font-size: 16px;
  position: relative;
  font-weight: 500;
  top: -2px;
  padding: 0 1px;
}
.tire_size_width_obj_17,
.tire_size_height_obj_17,
.tire_size {
  display: table-cell;
  font-size: 24px !important;
}

.wheel_tire_obj_17 .prop_name,
.tire_size_width_obj_17 .prop_name,
.tire_size_height_obj_17 .prop_name,
.tire_size .prop_name {
  display: none;
}

.tire_size_height_obj_17 .unit,
.tire_size_width_obj_17 .unit,
.tire_size .unit {
  display: none;
}

/* .year_dep_2::before {
  content: "|";
  position: absolute;
  margin-left: -10px;
  font-size: 12px;
  font-weight: 200;
} */
.spec_obj_20_prop_32 .prop_name {
  display: none;
}
.spec_obj_20_prop_32 {
  display: table-cell;
}
.spec_obj_20_prop_32 .prop_val {
  font-size: 24px;
  margin-left: 10px;
}

.spec_obj_20_prop_32:before {
  content: ".";
  position: relative;
  top: -4px;
  left: 5px;
  font-size: 20px;
  font-weight: 700;
}
.tag {
  display: inline-block;
  padding: 5px 9px 3px 9px;
  line-height: 1;
  cursor: pointer;
  margin: 2px 4px 5px 0;
  border: solid 1px #ccc;
  transition: all 0.1s;
  border-radius: 15px;
  user-select: none;
  position: relative;
  font-size: 13px;
  /* line-height: 1.6; */
  background: #e2ffac;
  font-family: Segoe_UI_Historic, serif;
  letter-spacing: 0;
  font-weight: 600;
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgb(232, 252, 255) 100%); */

  /* background: linear-gradient(180deg, rgba(255, 255, 255, 1) 40%, rgba(223, 255, 255, 1) 100%); */
}

.icon {
  vertical-align: middle;
  /* position: relative; */
  /* top: 4px; */
}

.boffer_num {
  font-weight: 600;
  /* border-bottom: solid 1px #ccc; */
  padding: 8px 12px 8px 12px;
  border-radius: 5px;
  background-color: aquamarine;
}

@media only screen and (min-width: 701px) {
  .barter {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 700px) {
  .row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .title,
  .body {
    padding: 0 10px !important;
  }
  .prop_name {
    padding-left: 10px;
  }
  /* .body {
    word-wrap: anywhere;
    width: fit-content !important;
    position: relative;
    left: 50%;
    transform: translateX(-50%) !important;
    max-width: 95% !important;
  } */

  .title .prop_val {
    /* font-family: Segoe_UI_Historic, system-ui; */
    letter-spacing: 0;
    font-weight: 550;
    font-size: 18px;
    /* font-size: calc(0.3em + 1vw); */
  }
  /* .title {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    text-align: center;
    padding: 0px 35px;
    margin-bottom: 10px;
  } */
  .time_since {
    padding: 6px 0px 4px 10px !important;
    line-height: 2.5;
    width: 100%;
    text-align: left;
  }

  .price {
    position: fixed;
    bottom: 15px;
    left: 0px;
    z-index: 1;
  }
}
</style>
