<template>
  <div class="cont_side_panel">
    <div v-if="user">
      <div v-if="viewCount" class="ml-2" :class="{ 'ml-10px': scr == 1 }"><ui-icon name="i-view2" class="eye" size="l" /> {{ viewCount }}</div>
      <div :class="scr == 1 ? 'hori_max' : 'hori'"></div>
      <div class="user_info" :class="{ 'ml-10px': scr == 1 }">
        <span class="new mr-1" v-if="user.joined > Date.now() / 1000 - 60 * 60 * 24 * 7">шинэ</span>
        <!-- <ui-icon class="merit" v-if="Date.now() - user.joined > 1000 * 60 * 60 * 24 * 30 && user.merit == 2 && user.item > 1" name="i-user" size="xxl"></ui-icon> -->
        <ui-icon class="merit" name="i-user" size="xxl"></ui-icon>
        <span class="user_name">
          {{ userType ? userType : "Энгийн хэрэглэгч" }} <span v-if="user.verified"><img src="/icon/i-correct.svg" height="12"/></span>
        </span>
      </div>
      <div class="user_phone">
        <!-- <a :href="'tel:' + user.phone"><ui-icon class="icon_phone mr-1" name="phone" size="xxxl" /> {{ String(user.phone).substr(0, 4) + "-" + String(user.phone).substr(4, 8) }} </a> -->
        <ui-icon class="icon_phone mr-1" name="i-phone" size="xxxxl" /><a :href="isMobile && itemPhone ? 'tel:' + itemPhone : 'javascript:void(0)'" @click="!itemPhone ? viewPhone() : ''">
          {{ String(itemPhone ? itemPhone : user.phone).substr(0, 4) + "-" + String(itemPhone ? itemPhone : user.phone).substr(4, 8) }}</a
        >
      </div>

      <div class="user_action" v-if="myItem && userLogged">
        <div class="link mb-1" @click="userAction('renew')"><ui-icon name="i-refresh" class="action_icon" /> Шинэчлэх</div>
        <div class="link mb-1" @click="userAction('edit')"><ui-icon name="i-edit" class="action_icon" /> Засах</div>
        <div class="link" @click="userAction('delete')"><ui-icon name="i-delete2" class="action_icon" /> Устгах</div>
      </div>

      <!-- <div class="button_group">
        <span><ui-icon class="icon" name="i-user" size="xxxl" />{{ userType ? userType : "Энгийн хэрэглэгч" }}</span>
        <span><ui-icon class="icon" name="i-report" size="xxxl" />Мэдүүллэх</span>
        <span><ui-icon class="icon" name="i-bookmark" size="xxxl" />Тэмдэглэх</span>
        <span><ui-icon class="icon" name="i-facebook" size="xxxl" /> Facebook share</span>
        <span><ui-icon class="icon" name="i-twitter" size="xxxl" /> Жиргэх</span>
        <span><ui-icon class="icon" name="i-link" size="xxxl" /> Холбоос</span>
        <span><ui-icon class="icon" name="i-view2" size="xxxl" />{{ viewCount }} үзсэн</span>
      </div> -->

      <div class="user_fav pt-1" v-if="!userLogged || (userLogged && !myItem)">
        <ui-icon :name="isFaved ? 'i-bookmark2' : 'i-bookmark'" :class="[isFaved ? 'bookmarked' : 'bookmark']" size="xxxl" /> <ui-button :text="isFaved ? 'Тэмдэглэсэн' : 'Тэмдэглэх'" v-on:clicked="favChange(obj, zid)" class="clickable" />
      </div>
      <!-- <div v-if="viewPhoneCount && myItem" class="mt-2 ml-2"><ui-icon name="phone" class="eye" size="l" /> {{ viewPhoneCount }}</div> -->
    </div>
    <div v-else class="attention ma-auto">Энэ зар идэвхигүй байна</div>
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
export default {
  name: "side-panel",
  props: {
    user: Object,
    obj: Number,
    zid: String,
    viewCount: Number,
    // viewPhoneCount: Number,
  },
  data() {
    return {
      itemPhone: null,
    };
  },
  methods: {
    async viewPhone() {
      this.itemPhone = await this.axiosGet("/user/item/viewItemPhone", [this.obj, this.zid, this.user.id]);
    },
    favChange(obj, zid) {
      if (this.userLogged) {
        this.$store.commit("user/favChange", { obj, zid });
      } else {
        this.$bus.emit("openModal", { name: "userLogin" });
      }
    },
    userAction(action) {
      if (!this.userLogged) {
        this.$bus.emit("openModal", { name: "userLogin" });
        return;
      }
      if (action == "renew") this.$store.dispatch("user/renewItem", { obj: this.obj, zid: this.zid });
      if (action == "edit") this.$store.dispatch("user/editItem", { obj: this.obj, zid: this.zid });
      if (action == "delete") {
        this.$modal.show("dialog", {
          title: "Зар устгах",
          text: "Таны устгасан зар хэсэг хугацаанд идэвхигүй байрших бөгөөд энэ хугацаанд та устгасан зартайгаа ижил зар оруулж болохгүйг анхаарна уу",
          buttons: [
            {
              title: "Устгах",
              handler: () => {
                this.$store.dispatch("user/deleteItem", { obj: this.obj, zid: this.zid, statusCode: 2 });
                this.$modal.hide("dialog");
                this.$emit("reloadItem");
              },
            },
            {
              title: "Болих",
              handler: () => {
                this.$modal.hide("dialog");
              },
            },
          ],
        });
      }
    },
  },
  computed: {
    scr() {
      return this.$store.getters.scr;
    },
    userType() {
      if (!this.user) return null;

      let tag = null;
      switch (this.user.role) {
        case 1:
          tag = "Энгийн хэрэглэгч";
          break;
        case 2:
          tag = "Хувь хүн (премиум)";
          break;
        case 3:
          tag = "ААН";
          break;
        case 4:
          tag = "ААН (премиум)";
          break;
        case 5:
          tag = "Админ";
          break;
        default:
          break;
      }
      return tag;
    },
    userLogged() {
      return this.$store.state.auth.status.logged ? this.$store.state.auth.user : false;
    },
    isFaved() {
      return this.$store.state.user.favs.find((e) => e.zid == this.zid);
    },
    myItem() {
      return this.userLogged && this.user.id == this.userLogged.uid;
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },
  watch: {
    user() {
      this.itemPhone = null;
    },
  },
  mounted() {},
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_side_panel {
  /* margin: 0; */
  position: relative;
}

.bookmark {
  position: relative;
  top: 8px;
  margin-right: 2px;
  opacity: 1;
  /* filter: invert(35%) sepia(97%) saturate(2698%) hue-rotate(2deg) brightness(104%) contrast(105%); */
}
.bookmarked {
  position: relative;
  top: 8px;
  margin-right: 2px;
  filter: invert(35%) sepia(97%) saturate(2698%) hue-rotate(2deg) brightness(104%) contrast(105%);
}
.user_phone a {
  text-decoration: none;
  font-size: 2em;
  font-weight: inherit;
  background: none;
}

.user_phone {
  position: relative;
  width: max-content;
  padding: 10px 10px 5px 10px;
  padding-left: 46px;
  /* border-radius: 2px; */
  margin-top: 3px;
  white-space: nowrap;
  background-color: #f1f1f1;
  line-height: 1.2;
  transition: all 150ms;
  /* transform: scale(1); */
}
.user_phone:active {
  /* transform: scale(1.05); */
  filter: brightness(0.9);
}

.user_phone:hover {
  filter: saturate(1.5);
  cursor: pointer;
}

.label_phone {
  color: var(--color_lighter);
  margin-bottom: 5px;
  font-size: 14px;
}

.icon_phone {
  position: absolute;
  top: 7px;
  left: 11px;
}
.user_fav {
  margin-top: 10px;
  margin-left: 2px;
}
.user_action {
  margin: 10px 0 0 5px;
  /* border: solid 1px #e4e4e4; */
  padding: 5px 10px;
  width: fit-content;
  border-radius: 8px;
  background-color: rgb(252, 224, 255);
}
.user_action a {
  display: block;
  padding: 2px 0;
}
.user_action icon {
  position: relative;
  top: 15px;
}
.cont_fav {
  margin: 3px 0;
}

.fav_icon {
  position: relative;
  top: 6px;
  margin-right: 3px;
}
.cont_fav:hover {
  cursor: pointer;
}

.action_icon {
  position: relative;
  top: 1px;
  left: -2px;
  opacity: 0.8;
}
.user_info {
  margin-top: 10px;
  margin-bottom: 10px;
}
.user_info .merit {
  position: relative;
  top: 5px;
}
.user_info .new {
  background-color: cornflowerblue;
  color: #fff;
  line-height: 100%;
  padding: 0px 2px;
  border-radius: 3px;
  font-size: 11px;
}
.user_name {
  margin-left: 0px;
  padding-bottom: 100px;
}
.eye {
  position: relative;
  top: 3px;
}

/* .button_group {
  display: grid;
  grid-gap: 7px;
  transition: all 1s;
  text-align: left;
  margin-top: 20px;
}

.button_group span {
  padding: 0px;
}

.button_group img {
  vertical-align: middle;
  margin-right: 5px;
} */

@media only screen and (max-width: 700px) {
  /*  .user_phone {
    position: fixed;
    bottom: 30px;
    right: 0px;
    z-index: 1;
    background-color: #c8fbff !important;
  }

  .user_phone a {
    font-size: 1.7em;
  }*/
}
</style>
