<template>
  <span>
    <ch-drawer :visible.sync="drawer.viewItem.open" @open="openedViewItemdrawer()" @close="closedViewItemdrawer()" area="100%" :blur="false" location="right" mask :escap-on-press="false" destroy-on-close>
      <zar-view v-if="drawer.viewItem.open" :dataSet="drawer.viewItem.dataSet" drawerMode />
      <!-- <zar-view v-on:close="closeViewItemDrawer" :dataSet="drawer.viewItem.dataSet" drawerMode /> -->
      <div v-if="scr == 1" class="return_button_right" @click="closeViewItemDrawer"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
      <div v-if="scr == 1" class="return_button_special ani_pop" @click="closeViewItemDrawer"><ui-icon class="back_icon" angle="rev" name="i-back2" size="xxxxl" /></div>
      <div class="return_button_top" @click="closeViewItemDrawer"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
    </ch-drawer>

    <!-- <van-popup v-model="drawer.viewItem.open" :duration="0.2" @open="$store.commit('addOpenDrawers', 'zarView')" @close="$store.commit('removeOpenDrawers', 'zarView')" closeable position="bottom" :style="{ height: '90%' }">
      <div class="mt-4"></div>
      <zar-view v-on:close="drawer.viewItem.open = false" :dataSet="drawer.viewItem.dataSet" drawerMode />
      <div v-if="scr == 1" class="return_button_right_fixed" @click="drawer.viewItem.open = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
    </van-popup> -->

    <!-- <ch-drawer :visible.sync="drawer.viewAllItems.open" @open="$store.commit('addOpenDrawers', 'zarView')" @close="$store.commit('removeOpenDrawers', 'zarView')" v-on:close="drawer.viewAllItems.open = false" area="100%" :blur="false" location="right" :mask="true">
      <div v-if="drawer.viewAllItems.dataSet">
        <div v-for="(z, ind) in drawer.viewAllItems.dataSet.items" :key="'viewAllItems' + ind">
          <zar-view :dataSet="z" drawerMode />
        </div>
      </div>
      <div v-if="scr == 1" class="return_button_right" @click="drawer.viewAllItems.open = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
    </ch-drawer> -->

    <ch-drawer :visible.sync="drawer.userFavs.open" :area="scr == 1 ? '100%' : '500px'" title="Тэмдэглэсэн зар" :blur="false" :location="scr == 1 ? 'bottom' : 'right'" :mask="scr == 1" @open="userFavsOpen" @close="userFavsClose" destroy-on-close>
      <user-favs v-if="drawer.userFavs.dataSet" v-on:close="drawer.userFavs.open = false" :propObj="drawer.userFavs.dataSet.obj" :propZid="drawer.userFavs.dataSet.zid" :drawerMode="scr == 1" />
      <div v-if="scr == 1" class="return_button_right" @click="drawer.userFavs.open = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
      <div class="return_button_top" @click="drawer.userFavs.open = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
    </ch-drawer>

    <ch-drawer :visible.sync="drawer.addBuy.open" :escape-on-click="false" :area="scr == 1 ? '100%' : '500px'" :title="selObj ? selObj.name + ' худалдаж авах зар нэмэх' : ''" :blur="false" :location="scr == 1 ? 'bottom' : 'right'" destroy-on-close>
      <mod-add-buy v-on:close="drawer.addBuy.open = false" />
      <div v-if="scr == 1" class="return_button_right" @click="drawer.addBuy.open = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
      <div class="return_button_top" @click="drawer.addBuy.open = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
    </ch-drawer>

    <ch-drawer :visible.sync="showMenu.cat" area="280px" :location="scr == 1 ? 'bottom' : 'left'" :blur="false" @open="$store.commit('addOpenDrawers', 'showMenuCat')" @close="$store.commit('removeOpenDrawers', 'showMenuCat')" destroy-on-close>
      <!-- <zar-cat v-if="catData" withVirtualCat disableFunc onlyCat /> -->
      <zar-cat-main-mobile v-if="showMenu.cat" withVirtualCat disableFunc onlyCat v-on:clickedObj="clickedObjFromCatMobile($event)" :selCat="selCat" :selObj="selObj" />
      <div v-if="scr == 1" class="return_button_right" @click="showMenu.cat = false"><ui-icon class="back_icon" angle="270deg" name="i-close4" size="xxxxl" /></div>
      <div class="return_button_top" @click="showMenu.cat = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
    </ch-drawer>

    <!-- <ui-select-drawer
      :visible="drawer.selectDrawer.open"
      :compName="drawer.selectDrawer.dataSet.nameSelect"
      :title="drawer.selectDrawer.dataSet.title"
      showCloseButton
      :selection="drawer.selectDrawer.open"
      :items="drawer.selectDrawer.dataSet.items"
      location="bottom"
      v-on:clickedItem="clickedItemSelectDrawer($event, drawer.selectDrawer.dataSet.nameSelect)"
    /> -->
  </span>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import zarView from "@/comp/page/zarView/index.vue";
import userFavs from "@/comp/user/favs.vue";
import modAddBuy from "@/comp/mod/addBuy.vue";
import zarCatMainMobile from "@/comp/zar/catMainMobile.vue";
// import uiSelectDrawer from "@/comp/ui/selectDrawer.vue";

export default {
  name: "main-drawers",
  components: {
    zarView,
    userFavs,
    modAddBuy,
    zarCatMainMobile,
    // uiSelectDrawer
  },
  data() {
    return {
      drawer: {
        viewItem: { open: false, dataSet: null },
        viewAllItems: { open: false, dataSet: null },
        userFavs: { open: false, dataSet: null },
        addBuy: { open: false, dataSet: null },
        // selectDrawer: { open: false, dataSet: { name: "", nameSelect: "", title: "", items: [] } }
      },
    };
  },
  methods: {
    clickedItemSelectDrawer(ob, nameSelect) {
      this.$bus.emit(nameSelect, ob);
    },
    clickedObjFromCatMobile(ob) {
      this.showMenu.cat = false;
      if (this.$route.name != "home") this.$router.push({ name: "home", query: { o: ob.id } });
      else this.$bus.emit("forceSelObj", ob.id);
    },
    openedViewItemdrawer() {
      this.$store.commit("addOpenDrawers", "zarView");
      // this.$router.push({ hash: "itemView" });
    },
    closeViewItemDrawer() {
      this.drawer.viewItem.open = false;
      // this.$router.back();
    },
    closedViewItemdrawer() {
      this.$store.commit("removeOpenDrawers", "zarView");
    },
    openDrawer(ob) {
      switch (ob.name) {
        case "viewItem":
          this.drawer.viewItem.dataSet = ob;
          this.drawer.viewItem.open = true;
          break;
        // case "viewAllItems":
        //   this.drawer.viewAllItems.dataSet = ob;
        //   this.drawer.viewAllItems.open = true;
        //   break;
        case "userFavs":
          this.drawer.userFavs.dataSet = ob;
          this.drawer.userFavs.open = true;
          break;
        case "addBuy":
          this.drawer.addBuy.dataSet = ob;
          this.drawer.addBuy.open = true;
          break;
        // case "selectDrawer":
        //   console.log("opendrawer:", ob);

        //   this.drawer.selectDrawer.dataSet = ob;
        //   this.drawer.selectDrawer.open = true;
        //   break;
        default:
          break;
      }
    },
    closeDrawer(name) {
      this.drawer[name].open = false;
    },
    userFavsOpen() {
      this.$bus.emit("scrollLock", true);
      this.$store.commit("user/favsModalActive", true);
      this.$store.commit("addOpenDrawers", "userFavs");
    },
    userFavsClose() {
      this.$bus.emit("scrollLock", false);
      this.$store.commit("user/favsModalActive", false);
      this.$store.commit("removeOpenDrawers", "userFavs");
    },
    userNotiBeforeOpen() {
      this.$store.commit("user/notiModalActive", true);
    },
    userNotiBeforeClose() {
      this.$store.commit("user/notiModalActive", false);
      this.$store.dispatch("user/updateUserNoti");
    },
  },
  computed: {
    selCat() {
      return this.$store.state.selCat;
    },
    selObj() {
      return this.$store.state.selObj;
    },
    scr() {
      return this.$store.getters.scr;
    },
    showMenu: {
      get() {
        return this.$store.state.showMenu;
      },
      set(ob) {
        this.$store.commit("showMenu", ob);
      },
    },
  },
  watch: {
    // $route(to, from) {
    //   console.log(to, from);
    // if (from.hash == "#itemView") {
    //   this.drawer.viewItem.open = false;
    // }
    // },
  },
  mounted() {
    this.$bus.on("openDrawer", this.openDrawer);
    this.$bus.on("closeDrawer", this.closeDrawer);
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped></style>
