<template>
  <div class="cont_home_index">
    <main-install-pwa v-if="isMobile" />
    <!-- <div class="test1">Test</div>
    <div class="test2">Test</div>
    <div class="test3">Test</div>
    <div class="test4">Test</div> -->

    <!-- <ui-reflow> -->
    <!-- <div v-if="selCat == 11" class="v_header" :class="{ scale_bigger: scr == 1 }" :style="{ transform: !isMobile && 'scale(1)' }"> -->
    <!-- <div class="v_header" :class="{ scale_bigger: scr == 1 }" :style="{ transform: !isMobile && 'scale(1)' }"> -->
    <!-- <img :src="apiUrl + (scr == 1 ? '/media/banners/welcome_mobile.png' : '/media/banners/welcome.png')" /> -->
    <!-- </div> -->
    <!-- </ui-reflow> -->
    <div class="front" v-show="!selObj">
      <!-- <div class="front" v-if="!selObj && selCat == 11"> -->
      <div class="welcome_svg" v-if="true"><img src="/icon/i-front2.svg" /></div>
      <zar-cat-main v-if="catData" :catData="catData" />
      <!-- <zar-cat-main v-if="catData" :catData="catData" /> -->
      <zar-list-home-front v-if="false && !selObj" :prevZar="prevZar" />
      <a :href="baseUrl + '/zar/add'" v-on:click.prevent=""><div class="add_zar" role="button" @click="zarAdd()">Зар оруулах</div></a>
    </div>
    <!-- <div v-if="scr < 3 && selCat && selCat != 11 && !selObj" class="info_text">Та зарын ангилал доторх <span class="link b u" @click="showMenu.cat = true">төрлийг сонгон</span> уу</div> -->
    <div class="row" v-show="selObj">
      <div class="col1 pt-1">
        <zar-cat v-if="scr > 2 && catData" autoSelFirst />
      </div>
      <div class="col2">
        <h3 v-if="selObj && false" class="mb-3">{{ selObj.name }}</h3>
        <div class="filter">
          <div v-if="frontPage" class="new_items mt-3">Шинэ зарууд</div>
          <zar-filter-home />
          <!-- <ui-icon v-if="selObj && mainOnly" name="i-more" size="xxxxl" class="hoverable op-04" @click="mainOnly = false"></ui-icon> -->
          <!-- <span v-if="selObj" @click="mainOnly = !mainOnly" class="clickable"> {{ mainOnly ? "..." : "Нуух" }}</span> -->
        </div>
      </div>
      <div class="col3">
        <div v-if="scr < 4" class="mb-3 mt-02 ml-4" :class="{ 'ml-10px': scr == 1 }">
          <!-- <zar-bread v-if="scr < 3" class="mt-3" /> -->
          <!-- <ui-button v-if="scr < 4" v-on:clicked="showMenu.tools = true" class="pr-3 pl-3" text="Их үзсэн" /> -->
          <ui-button v-if="scr < 4" v-on:clicked="$router.push('/zar/add')" icon="i-add" class="pr-3 pl-3" text="Зар оруулах" tp="special" />
        </div>
        <div v-if="scr == 1" class="ml23 title_simple val-mid pt-3 ml-10px mb-1"><ui-icon name="i-view2" size="l" class="po-re top-1n" /> Өчигдөрийн их үзсэн</div>
        <zar-list-self v-if="scr == 1 && selObj" :nameSrc="'most_viewed'" v-on:clickedItem="clickedMostViewed($event)" nanoMode horizontalItems :cusFieldAllowed="['title']" :paged="false" class="mb-3" :customData="selObj ? selObj.id : null" />

        <barter-car v-if="scr == 1 && selObj && selObj.mod && selObj.mod.find((o) => o.module == 2)" horizontal bigItems showAllCars class="mb-4 mt-5" v-on:clickedItem="clickedBarterCar($event)" />

        <barter-car v-if="scr != 1 && selObj && selObj.mod && selObj.mod.find((o) => o.module == 2)" class="mb-4 mt-5" v-on:clickedItem="clickedBarterCar($event)" />

        <buy-list v-if="false && selObj && scr < 4" :obj="selObj" horizontal />

        <!-- <zar-video-items v-if="scr < 4" v-on:clickedItem="clickedMostViewed($event)" class="mb-5" /> DISABLED FOR MOBILE DATA  -->

        <div v-if="selObj && selObj.mod && selObj.mod.find((o) => o.module == 4)" class="mb-4">
          <span class="button_group button_list" @click="viewItemLoc = false" :class="{ selected: viewItemLoc == false }"><ui-icon name="i-list" size="xxl"/></span>
          <span @click="viewItemLoc = true" :class="{ selected: viewItemLoc == true }" class="button_group button_map"><ui-icon name="i-map3" size="xxl"/></span>
        </div>

        <div v-if="!viewItemLoc">
          <zar-filter-home v-if="scr == 1" />
          <zar-sort class="mb-3 mt-2" />
          <zar-tag :class="{ 'ml-10px': scr == 1 }" />
        </div>
        <zar-list-home v-if="selObj" :prevZar="prevZar" :viewItemLoc="viewItemLoc" />
      </div>
      <ui-reflow>
        <div class="col4" v-if="scr > 3 && selObj">
          <div class="add_zar2" role="button" @click="zarAdd()">Зар оруулах<img src="/icon/i-cursor-hand.svg" height="24" class="cursor_hand" /></div>

          <!-- <zar-video-items /> -->
          <buy-list v-if="selObj" :obj="selObj" />

          <div class="mt-5 mb-1 title_simple val-mid"><ui-icon name="i-view2" size="l" class="po-re top-1n" /> Өчигдөрийн их үзсэн</div>
          <zar-list-self :nameSrc="'most_viewed'" showLimit v-on:clickedItem="clickedMostViewed($event)" nanoMode :cusFieldAllowed="['title']" :paged="false" :customData="selObj ? selObj.id : null" />
          <br />
          <ui-reflow>
            <zar-map v-if="selObj && selObj.mod && selObj.mod.find((o) => o.module == 3)" />
          </ui-reflow>
        </div>
      </ui-reflow>

      <ch-drawer :visible.sync="showMenu.fil" area="70%" :title="'Шүүлтүүр'" :blur="false" location="bottom" @open="$store.commit('addOpenDrawers', 'showMenuFil')" @close="$store.commit('removeOpenDrawers', 'showMenuFil')">
        <zar-tag v-if="showMenu.fil" />
        <div class="mb-3"></div>
        <zar-filter-home v-if="showMenu.fil" />
        <div v-if="scr == 1" class="return_button_right" @click="showMenu.fil = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
        <!-- <div v-if="scr == 1 && filter.length" class="reset_button_drawer"><ui-button class="link" v-on:clicked="resetAll()" text="Арилгах" tp="simple" /></div> -->
        <!-- <div v-if="scr == 1" class="close_button_drawer"><ui-button class="link" v-on:clicked="resetAll()" text="Хаах" tp="simple" /></div> -->
        <div class="return_button_top" @click="showMenu.fil = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
      </ch-drawer>

      <ch-drawer
        :visible.sync="showMenu.barterCar"
        :area="scr == 1 ? '100%' : '530px'"
        :title="barterDataSet && barterDataSet.name + (barterDataSet.model == 99999999 ? ' гэсэн зарууд' : '-аар солих боломжтой машинууд')"
        :blur="false"
        location="right"
        :mask="scr == 1"
        @open="scr == 1 && $store.commit('addOpenDrawers', 'barterCar')"
        @close="barterCarDrawerClosed()"
        destroy-on-close
      >
        <barter-car v-if="selObj && selObj.mod && selObj.mod.find((o) => o.module == 2)" showAllCars class="mb-4" v-on:clickedItem="clickedBarterCar($event)" />

        <barter-list :dataSet="barterDataSet" />
        <div v-if="scr == 1" class="return_button_right" @click="showMenu.barterCar = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
        <!-- <barter-list :dataSet="barterDataSet" v-on:close="showMenu.barterCar = false" /> -->
        <div class="return_button_top" @click="showMenu.barterCar = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
      </ch-drawer>

      <ch-drawer v-if="scr < 4" :visible.sync="showMenu.tools" area="280px" :blur="false" location="right" :title="'Нэмэлт самбар'" @open="$store.commit('addOpenDrawers', 'showMenuTools')" @close="$store.commit('removeOpenDrawers', 'showMenuTools')" destroy-on-close>
        <div v-if="scr != 1">
          <div class="mb-3 title_simple val-mid"><ui-icon name="i-view2" size="l" class="po-re top-1n" /> Өчигдөрийн их үзсэн</div>
          <zar-list-self :nameSrc="'most_viewed'" v-on:clickedItem="clickedMostViewed($event)" nanoMode :cusFieldAllowed="['title']" :paged="false" :customData="selObj ? selObj.id : null" />
          <div v-if="scr == 1" class="return_button_right" @click="showMenu.tools = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
        </div>
        <br />
        <barter-car v-if="selObj && selObj.mod && selObj.mod.find((o) => o.module == 2)" class="mb-4" />
        <zar-map v-if="selObj && selObj.mod && selObj.mod.find((o) => o.module == 3)" class="mb-2" />
        <div class="return_button_top" @click="showMenu.tools = false"><ui-icon class="back_icon" angle="rev" name="i-close4" size="xxxxl" /></div>
      </ch-drawer>
    </div>
    <!-- <zar-filter-modal-home v-if="showFilterModal" /> -->
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import { mapState } from "vuex";

import zarCat from "./zarCat.vue";
import zarCatMain from "./zarCatMain.vue";
import zarListHome from "./zarList.vue";
import zarListHomeFront from "./zarListFront.vue";
import zarFilterHome from "./zarFilter.vue";
// import zarFilterModalHome from "./zarFilterModal.vue";
import zarTag from "@/comp/zar/tag.vue";
// import zarBread from "@/comp/zar/bread.vue";
import zarSort from "@/comp/zar/sort.vue";
import zarMap from "./zarMap.vue";
import barterCar from "@/comp/mod/barterCar.vue";
import zarListSelf from "@/comp/zar/listSelf.vue";
// import zarVideoItems from "@/comp/zar/videoItems.vue";
import barterList from "@/comp/mod/barterList.vue";
import buyList from "@/comp/mod/buyList.vue";
import mainInstallPwa from "@/comp/main/installPwa.vue";

export default {
  name: "home-index",
  components: {
    zarCat,
    zarListHome,
    zarListHomeFront,
    zarTag,
    // zarBread,
    zarFilterHome,
    zarSort,
    zarMap,
    zarCatMain,
    barterCar,
    zarListSelf,
    // zarVideoItems,
    barterList,
    buyList,
    mainInstallPwa,
  },
  metaInfo() {
    return {
      title: this.selCat && this.selCat.id == 11 ? this.$store.state.appName + " - Зарын цогц систем" : (this.newZar.length ? "(" + this.newZar.length + ") " : "") + this.currentBread + " " + this.$store.state.appName,
      meta: [
        { name: "Бүх төрлийн зарын вэб" },
        { property: "og:title", content: "Зарын цогц систем" },
        { property: "og:site_name", content: "zaraa.mn" },
        { property: "og:description", content: "Бүх төрлийн зарын вэб, үл хөдлөх, машин, гар утас, компьютер" },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://zaraa.mn" },
        { property: "og:image", content: "https://zaraa.mn/images/icons-512.png" },
      ],
    };
  },
  data() {
    return {
      prevZar: null,
      viewItemLoc: false,
      soundTap: new Audio("sound/tap.wav"),
      barterDataSet: null,
      // showTopBanner: false,
    };
  },
  beforeRouteEnter: (to, from, next) =>
    next((vm) => {
      vm.prevZar = from.params.zid;
    }),
  methods: {
    barterCarDrawerClosed() {
      if (this.scr == 1) this.$store.commit("removeOpenDrawers", "barterCar");
      this.$store.commit("barterCarClickedInd", null);
    },
    resetAll() {
      this.$bus.emit("clearSort");
      this.$bus.emit("resetFilter");
    },
    clickedBarterCar(ob) {
      this.barterDataSet = ob;
      this.showMenu.barterCar = true;
    },
    clickedMostViewed(items) {
      this.showMenu.tools = false;
      this.$store.commit("zar/itemSource", { name: "Их үзэлттэй зар", src: "most_viewed", data: items });
      // this.$router.push({ name: "zarView", query: { o: obj, z: zid } });
    },
    zarAdd() {
      this.$router.push({ name: "zarAdd" });
    },
    // showFilterModal() {
    // this.$modal.show(zarFilterModalHome, {}, { height: "auto", width: "300px", maxWidth: 400, adaptive: !this.isMobile, scrollable: this.isMobile });
    // },
    checkUrlPar() {
      if (this.selObj)
        this.updateUrl(
          this.selObj.id,
          this.filter.map((f) => f.prop + "." + f.sid).join(),
          this.sort.filter((e) => e.sel).map((s) => s.id + "." + s.sel)
        );
    },
    updateUrl(obj, filter, sort) {
      let path = "/";
      // let path = "/home";
      if (obj) path += "?o=" + obj;
      if (filter) path += "&f=" + filter;
      if (sort && sort.length) path += "&s=" + sort;
      if (path != "/") history.replaceState(null, null, path);
      // if (path != "/home") history.replaceState(null, null, path);
      // console.log("homeUrl:", path);

      this.$store.commit("homeUrl", path);
    },
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position.coords.latitude);
          console.log(position.coords.longitude);
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
    async checkNewZar() {
      if (!this.lastZarCdate || !this.selObj) return;

      let data = await this.axiosGet("/zar/item/getNewItems", [this.selObj.id, this.lastZarCdate]);
      let count = data.length;
      if (count) {
        this.lastZarCdate = data[data.length - 1].cdate;
        this.$store.commit("addNewZar", data);

        // this.soundTap.play();
        // this.playSound("noti.mp3", 1);
        this.$message({
          type: "info",
          message: count + " зар нэмэгдлээ",
          showClose: false,
          hasMask: false,
          duration: 5000,
        });
      }
    },
  },
  computed: {
    ...mapState({
      sort: (s) => s.zar.sort,
      filter: (s) => s.zar.filter.filter.home,
      // selObj: (s) => s.selObj,
      selCat: (s) => s.selCat,
      catData: (s) => s.zar.catData,
      collect: (s) => s.user.collect,
    }),
    currentBread() {
      return this.$store.getters["zar/currentBread"];
    },
    selObj: {
      get() {
        return this.$store.state.selObj;
      },
      set(ob) {
        this.$store.commit("selObj", ob);
      },
    },
    lastZarCdate: {
      get() {
        return this.$store.state.lastZarCdate;
      },
      set(num) {
        this.$store.commit("lastZarCdate", num);
      },
    },

    showMenu: {
      get() {
        return this.$store.state.showMenu;
      },
      set(ob) {
        this.$store.commit("showMenu", ob);
      },
    },
    llc() {
      return false;
    },
    scr() {
      return this.$store.getters.scr;
    },
    newZar() {
      return this.$store.state.newZar;
    },
    showFilterModal() {
      return this.scr < 2 && this.showMenu.fil && this.$route.name == "home";
    },
    frontPage() {
      return this.selCat && this.selCat.id == 11;
    },
  },
  watch: {
    selCat() {
      if (this.frontPage) {
        this.selObj = null;
        history.replaceState(null, null, "/");
        // history.replaceState(null, null, "/home");
      }
    },
    selObj: {
      immediate: true,
      handler() {
        this.showMenu = { cat: false };
        this.viewItemLoc = false;
        // if (!this.selObj) {
        //   setTimeout(() => {
        //     if (!this.selObj) this.$modal.show(zarCatMain, {}, { height: "auto", width: "90%", maxWidth: 550, adaptive: true, clickToClose: false });
        //   }, 300);
        // }
      },
    },
    sort: {
      deep: true,
      handler() {
        this.checkUrlPar();
      },
    },
    filter() {
      this.checkUrlPar();
    },
  },
  mounted() {
    // this.showMenu = { cat: true };
    // setTimeout(() => {
    //   this.showMenu = { cat: false };
    // }, 1000);

    // this.$bus.on("showHomeFilterModal", this.showFilterModal);
    setInterval(() => this.checkNewZar(), this.scr == 1 ? 10000 : 30000);
    // this.showTopBanner = true;
    // setTimeout(() => (this.showTopBanner = false), 10000);
  },
  activated() {
    // this.checkUrlPar();
  },
  deactivated() {
    this.showMenu = { cat: false };
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_home_index {
  position: relative;
}
.icon {
  position: relative;
  left: 0px;
  top: 1px;
}
.fil {
  margin-top: 0px;
  text-align: left;
  margin-bottom: 5px;
  font-size: 12px;
  color: #505050;
}
.row {
  display: grid;
  grid-gap: 10px;
  transition: all 1s;
}
.front {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.welcome_svg {
  width: 100%;
  max-width: 200px;
  margin-top: 30px;
  transform: scaleX(-1);
}
.scale_bigger {
  /* transform: scale(1.1); */
}
.v_header {
  /* font-size: 26px; */
  /* line-height: 100%; */
  /* font-family: "Open Sans"; */
  /* font-weight: 600; */
  width: 100%;
  /* color: #ffffff; */
  /* text-align: center; */
  /* border-radius: 1px; */
  /* margin: 10px 0 20px 0; */
  /* line-height: 160px; */
  /* background-image: url("http://192.168.1.19/dev_backend/media/wallpaper/wall.jpg");*/
  /* background-color: #f1f1f1; */
  /* letter-spacing: 0.1em;  */
  padding: 0 0 30px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v_header img {
  width: 100%;
  /* aspect-ratio: 16/2; */
  transform: scale(1.05);
  z-index: -1;
}

.filter {
  top: 10px;
}
.cont_links {
  margin-bottom: 10px;
}
.tools {
  width: 100%;
  padding: 5px 10px;
  border-left: 2px solid #ccc;
}

.button_group {
  border: solid 1px #ccc;
  border-left: none;
  border-right: none;
  line-height: 1;
  padding: 5px;
  transition: all 0.2s;
}

.button_group img {
  position: relative;
  top: 6px;
  /* opacity: 0.5; */
}

.button_group:hover {
  cursor: pointer;
}

.button_group.selected {
  background-color: #f1f1f1;
  border: solid 1px #9b9b9b;
}

.button_group.selected img {
  opacity: 1;
}

.button_list {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left: solid 1px #ccc;

  /* border-right: none; */
}
.button_map {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-right: solid 1px #ccc;
}
/* .add_zar {
  margin-top: 20px;
  width: fit-content;
  padding: 10px 20px 8px 20px;
  background-color: var(--color_main);
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
} */

/* CSS */
.add_zar {
  margin-top: 50px;
  background-color: #ff5100;
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  /* font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
  font-size: 16px !important;
  text-transform: uppercase;
  font-weight: 500;
  justify-content: center;
  /* line-height: 1.75rem; */
  /* padding: 10px 20px 10px 20px; */
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  /* text-decoration-thickness: auto;
  width: 100%;
  max-width: 200px; */
  position: relative;
  cursor: pointer;
  transform: rotate(-2deg);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  padding: 15px 20px 10px 20px;
  /* line-height: 3; */
  /* text-shadow: 0px 0px 3px #0000007e; */
  transition: filter 100ms;
  border-radius: 5px;
}

.add_zar:hover {
  filter: saturate(1.5);
  /* text-shadow: 0px 0px 3px #fbff007e; */
}

.add_zar:focus {
  outline: 0;
}

.add_zar:after {
  content: "";
  position: absolute;
  /* border: 1px solid #fff; */
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

/* .add_zar:hover:after {
  bottom: 2px;
  left: 2px;
} */
.add_zar2 {
  margin-bottom: 30px;
  background-color: #ff5100;
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  /* font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
  font-size: 14px !important;
  text-transform: uppercase;
  font-weight: 500;
  justify-content: center;
  /* line-height: 1.75rem; */
  /* padding: 10px 20px 10px 20px; */
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  /* text-decoration-thickness: auto;
  width: 100%;
  max-width: 200px; */
  position: relative;
  cursor: pointer;
  transform: rotate(-2deg);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  padding: 12px 20px 10px 20px;
  width: calc(60% - 1px);
  height: calc(80% - 1px);
  transition: all 0.3s;
  border-radius: 5px;
}

.add_zar2:focus {
  outline: 0;
}

.add_zar2:after {
  content: "";
  position: absolute;
  /* border: 1px solid #fff; */
  bottom: 4px;
  left: 4px;
  width: calc(90% - 1px);
  height: calc(80% - 1px);
}
.add_zar2:hover {
  font-weight: 500;
}

.add_zar2:hover:after {
  bottom: 2px;
  left: 2px;
}

.add_zar2:hover .cursor_hand {
  opacity: 0;
  transform: scale(0);
}

.cursor_hand {
  top: 40px;
  right: 10px;
  position: absolute;
  transition: all 0.2s;
}
.reset_button_drawer {
  position: absolute;
  right: 20px;
  top: 65px;
}

/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.button-29:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-29:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
}

@media only screen and (min-width: 1150px) {
  .row {
    grid-template-columns: 1.2fr 1.2fr 3fr 1fr;
    /* grid-template-columns: 1fr 15fr; */
  }
  .col3 {
    display: block;
  }

  .col4 {
    padding-top: 10px;
  }
  /* .col2 {
    padding-top: 0px;
  }
  .col2 {
    padding-top: 8px;
  }
  .col3 {
    padding-top: 10px;
  }
  .col4 {
    padding-top: 45px;
  } */
}

@media only screen and (max-width: 1149px) {
  .col4 {
    display: none;
  }
  .row {
    grid-template-columns: 1fr 1fr 3fr;
  }
}

@media only screen and (max-width: 900px) {
  .col1 {
    display: none;
  }
  .row {
    grid-template-columns: 1fr 3fr;
  }
}

@media only screen and (max-width: 700px) {
  .col2 {
    display: none;
  }
  .row {
    grid-template-columns: 1fr;
  }
}

/* .test1 {
  background-color: #3c4fe0;
  border: solid 1px #000;
  line-height: 1;
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  width: min-content;
  margin-bottom: 10px;
  border-radius: 5px;
  vertical-align: super;
}
.test2 {
  background-color: #3c4fe0;
  border: solid 1px #000;
  line-height: 1;
  padding: 5px 10px;
  font-size: 13px;
  color: #fff;
  width: min-content;
  margin-bottom: 10px;
  border-radius: 5px;
}
.test3 {
  background-color: #3c4fe0;
  border: solid 1px #000;
  line-height: 1;
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  width: min-content;
  margin-bottom: 10px;
  border-radius: 5px;
}
.test4 {
  background-color: #3c4fe0;
  border: solid 1px #000;
  line-height: 1;
  padding: 5px 10px;
  font-size: 15px;
  color: #fff;
  width: min-content;
  margin-bottom: 10px;
  border-radius: 5px;
}
.test5 {
  background-color: #3c4fe0;
  border: solid 1px #000;
  line-height: 1;
  padding: 5px 10px;
  font-size: 16px;
  color: #fff;
  width: min-content;
  margin-bottom: 10px;
  border-radius: 5px;
} */
</style>
