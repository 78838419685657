<template>
  <div class="cont_ui_photos">
    <div class="cont_image_first">
      <span v-if="firstItem" class="cont_first" :class="{ loading: !itemsFinishedLoaded.includes(0) }">
        <div class="blink_me_local" v-if="!itemsFinishedLoaded.includes(0)">.</div>
        <img class="photo" :src="allItemsLoaded ? firstItem.src : firstItem.msrc" @load="itemLoaded(0)" @click="showPhotoSwipe(0)" :alt="firstItem.alt" :ref="'photo' + 0" />
        <span class="photo_title_other" v-if="firstItem.title">.....</span>
      </span>
    </div>

    <div :class="['cont_image', items.length < 4 && 'one_column', items.length > 7 && 'three_column']">
      <template v-for="(p, i) in items">
        <span v-if="i != 0" :key="'ph' + i" class="cont_other" :class="{ loading: !itemsFinishedLoaded.includes(i) }">
          <div class="blink_me_local" v-if="!itemsFinishedLoaded.includes(i)">.</div>
          <!-- <img class="photo" :src="items.length > 7 || scr > 1 ? p.tsrc : p.msrc" @load="itemLoaded(i)" @click="showPhotoSwipe(i)" :alt="p.alt" /> -->
          <img class="photo" :src="isMobile && items.length < 10 ? p.msrc : p.tsrc" @load="itemLoaded(i)" @click="showPhotoSwipe(i)" :alt="p.alt" :ref="'photo' + i" />
          <span class="photo_title_other" v-if="p.title">.....</span>
        </span>
      </template>
    </div>
    <PhotoSwipe :isOpen="isOpen" :items="allItemsLoaded ? modifiedItems : items" :options="options" @close="hidePhotoSwipe"></PhotoSwipe>
    <img v-if="preLoadSource" :src="preLoadSource" class="preload_first" />
  </div>
</template>
<!-------------------------------------------------------------------------------------------------->
<script>
import PhotoSwipe from "@/comp/ui/photoSwipe.vue";

export default {
  name: "ui-photos",
  components: { PhotoSwipe },
  props: {
    items: Array,
    byOne: { type: Boolean, default: false },
    preLoadSource: null,
    drawerMode: Boolean,
  },
  data() {
    return {
      isOpen: false,
      seeDate: false,
      itemsFinishedLoaded: [],
      isOpenGallery: false,
      options: {
        // Gallery options
        maxSpreadZoom: 1,
        getDoubleTapZoom: (isMouseClick) => {
          return isMouseClick ? 1.2 : 0.5;
          // return isMouseClick ? 1.4 : 1.4;
        },
        // getDoubleTapZoom: (isMouseClick, item) => {
        //   if (this.isMobile) {
        //     if (isMouseClick) {
        //       return 2; //<---- This 4
        //     } else {
        //       //return item.initialZoomLevel < 0.7 ? 2 : 1.33; //<---- 4 here
        //       return 0.7;
        //     }
        //   } else {
        //     return item.initialZoomLevel;
        //   }
        // },
        // UI options
        zoomEl: false,
        shareEl: false,
        index: 0,
        loop: false,
        preload: [1, 4],
        history: true,

        getThumbBoundsFn: () => {
          // console.log(this.$refs);
          return null;

          // if (this.isMobile && this.scr == 1 && this.getMobileOs() == "ios") {
          //   // var thumbnail = document.querySelectorAll(".photo")[index];
          //   // console.log(thumbnail);

          //   let element = index == 0 ? this.$refs["photo" + index] : this.$refs["photo" + index][0];

          //   var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
          //   // console.log("pageyscroll", pageYScroll);

          //   var rect = element.getBoundingClientRect();
          //   console.log(rect);

          //   // console.log({ x: rect.left, y: rect.top + pageYScroll, w: rect.width });

          //   return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
          // } else {
          //   return null;
          // }
        },
      },
    };
  },
  methods: {
    reset() {
      this.itemsFinishedLoaded = [];
    },
    clickedPhoto(ob, ind) {
      // console.log(ob);
      this.showPhotoSwipe(ind);
    },
    itemLoaded(ind) {
      if (!this.allItemsLoaded) this.itemsFinishedLoaded.push(ind);
      // if (!this.isMobile && ind == this.items.length - 1) this.allItemsLoaded = true;
    },
    showPhotoSwipe(index) {
      document.body.style.overflowY = "hidden";
      this.isOpen = true;
      this.$set(this.options, "index", index);
    },
    hidePhotoSwipe() {
      document.body.style.overflowY = "scroll";
      this.isOpen = false;
    },
    preloadImage(src) {
      const imageObj = new Image();
      imageObj.src = src;
    },
  },
  computed: {
    allItemsLoaded() {
      return this.itemsFinishedLoaded.length == this.items.length;
    },
    modifiedItems() {
      let cloned = this.deepClone(this.items);
      cloned.forEach((e, ind) => {
        if (ind == 0) e.msrc = e.src;
      });
      return cloned;
    },

    nextItem() {
      return this.items.find((e, i) => i == this.curItemInd + 1);
    },
    scr() {
      return this.$store.getters.scr;
    },
    maxHeight() {
      let img = this.items.map((e) => ({ h: e.h, w: e.w })).first();
      let height = parseInt((800 * img.h) / img.w);
      return (height > 450 ? 450 : height) + "px";
    },
    portrait() {
      let img = this.items.map((e) => ({ h: e.h, w: e.w })).first();
      return img.h >= img.w;
    },
    firstItem() {
      return this.items && this.items.length ? this.items.first() : null;
    },
  },
  mounted() {},
  watch: {
    items: {
      immediate: true,
      handler(valNew, valOld) {
        if (!this.isEqualArray(valNew, valOld)) this.reset();
      },
    },
  },
};
</script>
<!-------------------------------------------------------------------------------------------------->
<style scoped>
.cont_ui_photos {
  text-align: center;
  min-height: 150px;
  margin-bottom: 5px;
  padding-bottom: 0px;
  padding-top: 0px;
  transition: all 200ms;
}

.cont_ui_photos div {
  line-height: 0% !important;
}

.blink_me_local {
  animation: blinker 0.3s linear infinite;
  font-size: 100px;
  color: aqua;
  position: absolute;
  /* top: 10%; */
  /* left: 50%; */
  z-index: 5;
}

.cont_sep {
  content: "";
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  width: 80%;
  height: 1px;
  background-color: #ddd;
}

.cont_sep.top {
  top: 5px;
}
.cont_sep.bottom {
  bottom: 0px;
}

.cont_other {
  position: relative;
}

.cont_other img {
  transition: all 150ms;
  opacity: 1;
}

.cont_other.loading img {
  opacity: 0.5;
}

@media only screen and (min-width: 701px) {
  .photo {
    margin: 0 0px 0 0;
    max-width: 100%;
    max-height: 600px;
    z-index: 0;
    position: relative;
    border: solid 3px #ffffff;
    transition: all 100ms;
  }
  .photo:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }

  .current {
    filter: brightness(1.1) saturate(1.3) !important;
    /* transform: translateY(-3px); */
    transform: scale(1);
    z-index: 100;
    border: solid 3px #4385ff;
    /* box-shadow: 0px 0px 0px 0.5px rgba(250, 250, 250, 1); */
  }

  .others {
    filter: brightness(1);
  }
  .others:hover {
    opacity: 1;
  }

  .cont_image,
  .cont_other {
    vertical-align: middle;
    position: relative;
  }
  .cont_other .photo {
    max-width: 100%;
    max-height: 150px;
  }
  .cont_image {
    padding: 0px 0 0 0;
  }

  /* .cont_image .photo {
    max-width: 160px;
    max-height: 160px;
    height: auto;
    width: auto;
  } */

  .photo_title {
    position: absolute;
    z-index: 10;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    background-color: hsl(0, 0%, 0%, 0.3);
    padding: 2px 5px;
    border-radius: 2px;
    font-size: 15px;
  }

  .photo_title_other {
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    font-size: 18px;
    line-height: 10%;
    color: darkgoldenrod;
  }

  .navi_photo {
    position: absolute;
    z-index: 11;
    background-color: hsl(0, 0%, 0%, 0.05);
    color: #fff;
    padding: 15px 20px;
    user-select: none;
    transition: all 100ms;
    border-radius: 5px;
    filter: brightness(0.5);
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }

  .navi_photo .icon {
    /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%); */
  }

  .navi_photo:active {
    transform: scale(0.97) translateY(-50%);
  }

  .navi_photo:hover {
    cursor: pointer;
    background-color: hsl(0, 0%, 0%, 0.1);
  }
  .navi_photo:active {
    filter: brightness(0);
  }
  .navi_photo.prev {
    left: 0px;
  }
  .navi_photo.next {
    right: 0px;
  }
  .navi_photo.dis {
    opacity: 0;
  }
}

@media only screen and (max-width: 700px) {
  .photo {
    transition: all 150ms;
    filter: brightness(1);
  }
  .photo:active {
    filter: brightness(1.2);
  }
  .one_column {
    column-count: 1 !important;
  }

  .three_column {
    column-count: 3 !important;
  }

  .photo_title {
    font-size: 13px;
  }
  .cont_image {
    line-height: 0;
    column-count: 2;
    column-gap: 3px;
    column-fill: balance;
  }
  .cont_image_first {
    line-height: 0;
    column-count: 1;
    column-gap: 3px;
  }
  .cont_image img,
  .cont_image_first img {
    width: 100% !important;
    height: auto !important;
    margin-bottom: 3px;
  }
  /* .cont_other.loading img {
    opacity: 0.5;
    transform: translateY(5px);
  } */
}
</style>
